import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import '../CSS/common.css'
import fileLogo from '../Images/image 10.png'
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as yup from 'yup';
import axios from 'axios';
import { baseURL } from './Apis';
import { encryptor } from './Encryption';

const isLatitude = (value: any) => {
  return !value || (parseFloat(value) >= -90 && parseFloat(value) <= 90);
}
const isLongitude = (value: any) => {
  return !value || (parseFloat(value) >= -180 && parseFloat(value) <= 180);
}
const validationSchemaSection = yup.object().shape({
  building: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  state_district_province: yup.string().required(),
  postal_code: yup.number().required(),
  region: yup.string().required(),
  latitude: yup.number().test(isLatitude).required(),
  longitude: yup.number().test(isLongitude).required(),
  lease_commencement_date: yup.mixed().test('is-date', 'Invalid date format', (value:any) => {
    return (
      !isNaN(Date.parse(value)) && // Checks if the value is a valid date string
      typeof value !== 'number'    // Ensures the value is not a number
    );
  }).required(),
  lease_expiration_date: yup.mixed().test('is-date', 'Invalid date format', (value:any) => {
    return (
      !isNaN(Date.parse(value)) && // Checks if the value is a valid date string
      typeof value !== 'number'    // Ensures the value is not a number
    );
  }).required(),
  annualized_base_rent: yup.number().required(),
  additional_facilities_cost: yup.number().required(),
  facilities_cost_fully_loaded: yup.number().required(),
  strategy_top_opportunities: yup.string().required(),
  strategy_head_count_adjustment_in_year_of_change: yup.number().required(),
  strategy_seat_count_adjustment_in_year_of_change: yup.number().required(),
  strategy_square_foot_adjustment_in_year_of_change: yup.number().required(),
  strategy_occupancy_adjustment_in_year_of_change: yup.number().required(),
  strategy_year_of_change_yyyy: yup.number().required(),
  capacity_seats: yup.number().required(),
  surplus_deficit_seat_capacity: yup.number().required(),
  parking_capacity: yup.number().required(),
  cost_employee: yup.number().required(),
  cost_occupant: yup.number().required(),
  cost_seat: yup.number().required(),
  sf_employee: yup.number().required(),
  sf_occupant: yup.number().required(),
  sf_seat: yup.number().required(),
  rentable_sf: yup.number().required(),
  cost_sf: yup.number().required(),
  cost_sf_1: yup.number().required(),
  critical_decision_date: yup.mixed().test('is-date', 'Invalid date format', (value:any) => {
    return (
      !isNaN(Date.parse(value)) && // Checks if the value is a valid date string
      typeof value !== 'number'    // Ensures the value is not a number
    );
  }).required(),
  revenues: yup.number().required(),
  rmo_months: yup.number().required(),
  total_employee_hc: yup.number().required(),
  total_occupancy_hc: yup.number().required()
});



const Bulk = () => {
  const authTokenTest =   sessionStorage.getItem("apiToken") === undefined? 0: sessionStorage.getItem("apiToken");   
  const authToken =encryptor.decryptData(authTokenTest,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [dataError, setDataError]=useState(false);
    const [fileattached, setFileattached]= useState(true);
    const [jsonData, setJsonData] = useState<any[] | null>(null);
    const [modalShow, setModalShow] = React.useState(false);
    const [digContent, setDigContent] = useState<React.ReactNode | null>(null);
    const [validationErrors, setValidationErrors]:any = useState({});
    const dropzoneOptions: DropzoneOptions = {
        accept: {
          'text/csv': ['.csv'],
          'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv']
        },
        maxFiles:1
      };
    var {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
      } = useDropzone(dropzoneOptions)
    const removeFile=()=>{
        acceptedFiles=[];
        setDataError(false);
    }
      const acceptedFileItems = acceptedFiles.map((file:any,index:any) => (<>
                <span style={{marginLeft:"10px",marginRight:"10px"}}>{file.path} - {file.size} bytes</span><i className="fa fa-times" style={{color:"red"}} onClick={removeFile} aria-hidden="true"></i>
      </>
      ));

      const handlePageRefresh = () => {
        // Refresh the page
        window.location.reload();
      };
    const redirectToSiteList = () => {
        window.location.href = '../siteList';
      };


    const uploadData=async()=>{
        setLoader(true);
        if(acceptedFiles.length>0)
        {
            setFileattached(true)
            var validatecolumn = CheckAllColumns(Object.keys(tableData[0] || {}))
            if (validatecolumn){
              var errorRows:any =[]
              const promises = tableData.map(async (data, key) => {
                const useremail = sessionStorage.getItem('useremail');
                const user_id = useremail === undefined ? '' : useremail;
                try {
                  await validationSchemaSection.validate(data, { abortEarly: false });
                } catch (error:any) {
                  error.inner.forEach((err:any) => {
                    const column = err.path;
                    if (!errorRows[key + 1]) {
                        errorRows[key + 1] = [];
                    }
                    errorRows[key + 1].push(column);
                    setValidationErrors(errorRows)
                    console.log(errorRows)
                });
                }
              });
              
              // Await all promises using Promise.all
              Promise.all(promises).then(async() => {
                console.log(errorRows.length);
                if(errorRows.length==0){
                  var bulkdataTable = await Promise.all(tableData.map(async (data) => {
                    const useremail = sessionStorage.getItem('useremail');
                    const user_id = useremail === undefined ? '' : useremail;
                
                    // Simulating an asynchronous operation (you may replace this with your actual async logic)
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    
                    return {
                      ...data,
                      user_id: user_id
                    };
                  }))
    
                  var link = baseURL + 'addLeaseData/upload';
          try {
          
             const checkUsername = await axios.post(link, bulkdataTable, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            });
            if (checkUsername.data.status === 200) {
              setDigContent(<span ><i className="fa fa-check-circle fa-lg mx-2" style={{ color: "#00A978" }}></i>Sites added successfully.</span>)
              setModalShow(true);
              const timeout = setTimeout(() => {
                redirectToSiteList();
              },2000)
                return () => clearTimeout(timeout);
            }  
          }
          catch (error) {
            // Handle errors here
            setDigContent(<span >Error occured. Please try again<i className="fa fa-exclamation mx-2"></i></span>)
            setModalShow(true)
            // You can set an error state or show an error message to the user
          }
    
                }
                else{
                  const resulterror = errorRows.slice(0, -1).join(', ') + (errorRows.length > 1 ? ' and ' : '') + errorRows.slice(-1);
                  /* setDigContent(<span >Please check selected file. File should contain valid values for all mandatory fields.
                  <br/>
                  Correct your data and try again<br/>
                  <span style={{textAlign:'left'}}>Following rows are having invalid/missing data:</span><br/>
                  {resulterror}
                  </span>) */
                  setDataError(true);
                  //setModalShow(true)
                } // This will execute after all promises have resolved
              });


            }
            else{
              setDigContent(<span >Required columns are missing<i className="fa fa-exclamation mx-2"></i></span>)
              setModalShow(true)
            }
        }
        else{
            setFileattached(false)
        }
        setLoader(false)
    }

    useEffect(()=>{
      if(acceptedFiles.length>0)
      {
        //console.log('file present');
        setFileattached(true)
        convertToJSON();
      }
      else{
        setJsonData([])
      }
    },[acceptedFiles])

    const convertToJSON=()=>{
    const file: File = acceptedFiles[0];
    const reader: FileReader = new FileReader();

    reader.onload = (upload) => {
      const fileData = upload.target?.result;

      if (file.name.endsWith('.csv')) {
        // Parse CSV file
        Papa.parse(fileData as string, {
          header: true,
          complete: (results:any) => {
            const filteredData = results.data.filter((row: any) =>
              Object.values(row).some((value: any) => value.trim() !== "")
            );

            if (filteredData.length === 0 && results.data.length > 0) {
      
     
    } else {
      setJsonData(filteredData);
    }
          },
          error: (error:any) => {
            console.error('CSV parsing error:', error);
          },
        });
      } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
        // Parse XLSX file
        const workbook = XLSX.read(fileData as string, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        // Convert 2D array to JSON
        if (data.length > 1) {
          const columns: any = data[0] || []; // First array as column headers
  const convertedData = data.slice(1).map((row: any, key: any) => {
    const rowData: any = {};

    // Check if the entire row is blank
    const isRowBlank = row.every((cell: any) => cell === undefined || cell === '');

    if (!isRowBlank) {
      columns.forEach((column: any, index: number) => {
        const cellValue = row[index] !== undefined ? row[index] : '';
        rowData[column] = cellValue;
      });

      return rowData;
    } else {
      return null; // Skip the entire row if it's blank
    }
  }).filter((row: any) => row !== null); // Filter out the skipped rows

  setJsonData(convertedData);
      } else {
        console.error('Empty data or no column headers found');
      }
    } else {
        console.error('Unsupported file format');
      }
    };

    if (file) {
      reader.readAsBinaryString(file);
    }
    }

    
  const columns = jsonData ? Object.keys(jsonData[0] || {}) : [];
  const tableData = jsonData || [];

  const tableColumns = columns.map((column,key) => ({
    name: column,
    selector: (row: any) => row[column],
    cell: (row:any, index:any) => {
      const rowErrors:any = validationErrors[index+1];
      const hasError = rowErrors?.includes(column);
      return (
        <div style={{
          padding: '10px',  // add some padding
          backgroundColor: hasError ? '#f2bcbc33' : 'transparent',
          border:hasError?'2px solid red':'',
          height: '100%' ,
          width:'100%' // ensures the entire cell is covered
      }}>
              {row[column]}
          </div>
      );
  },
    sortable: false,
  }));

 

  const CheckAllColumns =(data:any)=>{
    const requiredColumns=['additional_facilities_cost','address','annualized_base_rent','building','business_unit','capacity_seats','city',
    'client_position',
    'cost_employee',
    'cost_occupant',
    'cost_seat',
    'cost_sf',
    'cost_sf_1',
    'country_code',
    'country_name',
    'critical_decision_date',
    'document_type',
    'facilities_cost_fully_loaded',
    'floor',
    'latitude',
    'lease_commencement_date',
    'lease_expiration_date',
    'lease_expiration_year',
    'lease_id',
    'lease_type',
    'leased_owned',
    'legacy_company',
    'longitude',
    'opex_sga',
    'opex_total_revenues',
    'parking_capacity',
    'postal_code',
    'region',
    'rentable_sf',
    'revenues',
    'rmo_months',
    'sf_employee',
    'sf_occupant',
    'sf_seat',
    'sga',
    'site_id',
    'space_use',
    'state_district_province',
    'status',
    'strategy_head_count_adjustment_in_year_of_change',
    'strategy_notes',
    'strategy_occupancy_adjustment_in_year_of_change',
    'strategy_optional_committed_or_na',
    'strategy_quarter_of_change',
    'strategy_real_estate_initiative',
    'strategy_reduction_addition_or_as_is',
    'strategy_seat_count_adjustment_in_year_of_change',
    'strategy_square_foot_adjustment_in_year_of_change',
    'strategy_top_opportunities',
    'strategy_type',
    'strategy_year_of_change_yyyy',
    'sub_region',
    'surplus_deficit_seat_capacity',
    'total_employee_hc',
    'total_occupancy_hc',
    'vacant',
    'verified_yn']

    for (const key of requiredColumns) {
      if (data.indexOf(key)<0) {
        return false;
      }
    }
    return true;
  }


  const downloadFile =async()=>{
  var linkhref = 'https://www.portfolioone.io/api/lease_template';
  try {
    const response = await axios.get(linkhref, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: 'blob', // Important for handling binary data (e.g., files)
    });

    // Create a blob from the response data
    const blob = new Blob([response.data]);

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'lease_template.xlsx'; // Set desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    // Handle errors here
    console.error('Error downloading file:', error);

    // You can set an error state or show an error message to the user
  }
  }
  return (
    <>
    {loader == true ?
      <div id="loader-container">
        <div id="loader">
          <RotatingLines
            strokeColor="#0072B2"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      </div> : null}
 
 
    <div className='' style={{margin:"40px"}}>
      <div className="row mt-5 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: "1", textAlign: "left" }}>
                <i className="fa fa-arrow-circle-o-left fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"20px" }} onClick={(e) => redirectToSiteList()}></i>
                <label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"20px",color: "#0072B2"}} onClick={(e) => redirectToSiteList()}>&nbsp;Site List</label>
              </div>
              <div style={{ flex: "1", textAlign: "right" }}>
              <button type="button" style={{ backgroundColor: '#CCE3F0', color: "#0072B2", fontFamily: "Poppins-Medium" ,fontSize:"16px", border:"1px solid #0072B2"}} onClick={handlePageRefresh} className="btn btn-block rounded-pill mx-2" >
                Cancel
              </button>
                
                <button type="button" style={{ backgroundColor: '#0072B2', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} className="btn btn-block rounded-pill mx-2" onClick={uploadData}>
                  Upload data
                </button>
              </div>
             </div>
            </div>

           

    <div>
    
        <section className="container" style={{width:"30%"}}>
        <div className='row'>
  <div className="col-md-6 form-group" style={{ marginBottom: "10px", display:"flex", alignItems:"end", textAlign:"start" }}>
    <label style={{ textAlign: "start" }}>Select file<span style={{ color: "#FE8485" }}>*</span></label>
  </div>
  <div className="col-md-6 form-group" style={{ marginBottom: "10px", textAlign:"end" }}>
    <button className='btn' style={{backgroundColor:"#F6F9FE", color:"#247BA0", border:"2px solid #247BA0", borderRadius:"5px"}} onClick={downloadFile}>Sample data file</button>
  </div>
</div>

      <div {...getRootProps({ className: 'dropzone bulkcontainer' })}>
        <input {...getInputProps()} />
        <p><i className="fa fa-upload fa-3x" style={{color:"#0072B2"}} aria-hidden="true"></i></p>
        <label style={{color:"#25282B", fontFamily:"Poppins-Medium"}}>Drag & drop here</label>
        <label style={{color:"#00000080"}}>or</label>
        <label style={{color:"#0072B2", fontFamily:"Poppins-Medium",cursor:"pointer"}}>Browse File</label>
        <label style={{color:"#00000080"}}> (Only *.csv and *.xlsx file will be accepted.)</label>
        {acceptedFiles.length>0?
        <div className='attachedFile'>
        <img src={fileLogo} style={{ maxHeight: "4vh", maxWidth: "40vw" }} alt="Logo" />{acceptedFileItems}
        </div>:null}
        
      </div>
      {fileattached?null:
      <div className='row'>
  <div className="col-md-6 form-group" style={{ marginBottom: "10px", display:"flex", alignItems:"end", textAlign:"start" }}>
    <label style={{ textAlign: "start", color:"#FE8485" }}>Please import .csv or .xlsx file.</label>
  </div>
</div>}
    </section>
    </div>
    {jsonData && (
         <div className=''>
         <div className="offset-1 col-10" style={{ height: 200 }}>
         <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <div style={{ textAlign: "left" }}>
    <label style={{ fontFamily: "Poppins-SemiBold", fontSize: "20px", color: "#0072B2", marginBottom: "10px", marginTop: "10px" }}>
      Preview
    </label>
  </div>
  {dataError?
  <div style={{ textAlign: "right" }}>
    <label style={{ fontFamily: "Poppins-SemiBold", fontSize: "16px", color: "red", marginBottom: "10px", marginTop: "10px" }}>
      Please check selected file. File should contain valid values for all mandatory fields.
    </label>
  </div>:null}
</div>

         <DataTable
            columns={tableColumns}
            data={tableData}
            pagination
            className='dataTable'
            paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          
          noDataComponent={<label style={{fontFamily:"Poppins-Medium",fontSize:"18px",color:"#0072B2", marginBottom:"10px", marginTop:"10px"}} >No records found.</label>}
          />
        </div>
        </div>
      )}
      {/* <Dialog
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          onClick: () => { }, // Disable backdrop click
        }}
        PaperProps={{
          style: {
            textAlign: 'center',
            justifyContent:'center',
            position: 'absolute',
            top: '10%', // Adjust the distance from the top as needed
            },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {digContent}
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      <Snackbar
  anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
  open={modalShow}
  autoHideDuration={5000}
  onClose={() => setModalShow(false)}
  message={digContent}
  ContentProps={{
    className: 'notifyBar'
  }}
  key={'top' + 'center'}
  style={{marginTop:'100px'}}
/>
    </>
  )
}

export default Bulk